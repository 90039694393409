.barn-page {
    background-color: #1e1e1e;
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #2a2a2a;
  }
  
  h1 {
    margin: 0;
  }
  
  main {
    flex-grow: 1;
    padding: 1rem;
    overflow-y: auto;
  }
  
  section {
    margin-bottom: 2rem;
  }
  
  h2 {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #3a3a3a;
    padding-bottom: 0.5rem;
  }
  
  footer {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
    background-color: #2a2a2a;
  }
  
  .icon {
    font-size: 1.5rem;
  }