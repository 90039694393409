.login-container {
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
background-size: cover;
background-position: center;
}

.login-form {
background-color: rgba(0, 0, 0, 0.7);
padding: 2rem;
border-radius: 10px;
width: 100%;
max-width: 400px;
color: white;
}

h1 {
text-align: center;
margin-bottom: 2rem;
}

.form-group {
margin-bottom: 1rem;
}

label {
display: block;
margin-bottom: 0.5rem;
}

input {
width: 100%;
padding: 0.5rem;
border: none;
border-radius: 5px;
background-color: rgba(255, 255, 255, 0.1);
color: white;
}

input::placeholder {
color: rgba(255, 255, 255, 0.5);
}

.forgot-password {
display: block;
text-align: right;
color: white;
text-decoration: none;
font-size: 0.9rem;
margin-top: 0.5rem;
}

.login-button {
width: 100%;
padding: 0.75rem;
background-color: #333;
color: white;
border: none;
border-radius: 5px;
cursor: pointer;
font-size: 1rem;
margin-top: 1rem;
}

.login-button:hover {
background-color: #444;
}

.signup-link {
text-align: center;
margin-top: 1rem;
}

.signup-link a {
color: white;
text-decoration: none;
}

.signup-link a:hover {
text-decoration: underline;
}