/* Dashboard.css */

.dashboard-container {
    background-color: #121212;
    min-height: 100vh;
    color: white;
    padding: 16px;
}

.even-row {
    color: white;
    background-color: #8a0000;
}

.odd-row {
    background-color: #272727;
}

.top-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    border-bottom: 1px solid white;
    background-color: #1e1e1e;
    padding: 3%;
}

.day-slider-container {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
}

.table-container {
    background-color: #1e1e1e;
    color: white;
    border-radius: 10px;
}

.table-header-cell {
    background-color: white;
    color: white;
    font-weight: bold;
}

.table-body-cell {
    color: white;
}

.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    background-color: #1e1e1e;
    padding: 5%;
}

.icon-button {
    color: white;
}

.update-at {
    display: flex;
    justify-content: center;
    color: black;
    font-size: 0.8rem;
}

/* LOADER */

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}