.custom-table-container {
    max-height: 80vh; /* Adjust this value as needed */
    overflow-y: auto;
    overflow-x: auto;
  }
  
  .custom-table {
    font-size: 14px; 
    width: 100%;
    display: block;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 8px 4px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .custom-table th {
    background-color: #2f2f2f;
    font-weight: bold;
  }
  
  .custom-table tr:nth-child(even) {
    background-color: #464646;
  }
  
  @media screen and (max-width: 600px) {
    .custom-table th,
    .custom-table td {
      padding: 8px;
      font-size: 14px;
    }
  }

  .barn-button {
    background: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    text-align: left;
    text-decoration: underline;
  }
  
  .barn-button:hover {
    color: #007bff;
  }


/* CSS */
.button-2 {
  background-color: rgba(244, 244, 244, 0.519);
  border-radius: 8px;
  border-width: 2;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}